<template>
  <b-form-select
    :value="selectedCommuterType || null"
    :options="commuterTypesDropdown"
    @change="selectCommuterType($event)"
    class="select"
  ></b-form-select>
</template>
<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "select-profile",
  methods: mapMutations({
    selectCommuterType: "reports/SELECT_COMMUTER_TYPE",
  }),
  computed: {
    ...mapState({
      selectedCommuterType: (state) => state.reports.selectedCommuterType,
    }),
    ...mapGetters("reports", ["commuterTypesDropdown"]),
  },
};
</script>
