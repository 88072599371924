<template>
  <b-form-select
    :value="selectedWorkplace || null"
    :options="workplacesDropdown"
    @change="selectWorkplace($event)"
    class="select"
  ></b-form-select>
</template>
<script>
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      selectedWorkplace: (state) => state.workplaces.selectedWorkplace,
    }),
    ...mapGetters("workplaces", ["workplacesDropdown"]),
  },
  methods: {
    selectWorkplace(e) {
      this.$store.dispatch("workplaces/selectWorkplace", e);
    },
  },
};
</script>
