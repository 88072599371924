<template>
  <div>
    <SelectCompany :addCompany="false" />
    <Loading v-if="inquiriesLoading" />
    <Header
      v-if="selectedCompanyId && !inquiriesLoading && !inquiries.length"
      fluid
    >
      <div class="container">
        <h2>{{ $t("inquiry.no_inquiries_for_company") }}</h2>
        <span>{{ $t("inquiry.choose_another_company") }}</span>
      </div>
    </Header>
    <Header fluid v-if="selectedCompanyId && inquiries && inquiries.length">
      <template v-slot:header>
        <SelectInquiry />
      </template>
      <SelectWorkplace />
      <SelectProfile />
    </Header>
    <SubNavigation v-if="selectedCompanyId && selectedInquiryId" />
    <Container
      dark
      v-if="selectedCompanyId && selectedInquiryId"
      style="min-height: 80vh"
    >
      <router-view></router-view>
    </Container>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import SubNavigation from "../components/SubNavigation.vue";
import Container from "../components/Container.vue";
import SelectCompany from "../components/forms/SelectCompany.vue";
import { mapGetters, mapState } from "vuex";
import SelectInquiry from "../components/forms/SelectInquiry.vue";
import SelectWorkplace from "../components/forms/SelectWorkplace.vue";
import Loading from "../components/Loading.vue";
import SelectProfile from "../components/forms/SelectProfile.vue";

export default {
  name: "Dashboard",
  metaInfo() {
    return {
      title: this.$t("navigation.results"),
    };
  },
  components: {
    Header,
    SubNavigation,
    Container,
    SelectCompany,
    SelectInquiry,
    SelectWorkplace,
    Loading,
    SelectProfile,
  },
  data() {
    return {
      selectedProfile: null,
      selectedLocation: null,
      profiles: [
        {
          value: null,
          text: this.$t("common.select"),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedCompanyId: (state) => state.companies.selectedCompany.id,
      companies: (state) => state.companies.companies,
      selectedInquiryId: (state) => state.inquiries.selectedInquiryId,
      inquiries: (state) => state.inquiries.inquiries,
      inquiriesLoading: (state) => state.inquiries.isLoading,
      selectedWorkplace: (state) => state.workplaces.selectedWorkplace,
    }),
    ...mapGetters("inquiries", ["userInquiries", "inquiriesDropdown"]),
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  border-radius: 5px;
  background-color: #fff;
  padding: 2rem;
}
.select {
  max-width: 200px;
  font-size: 0.85em;
  &-heading {
    font-size: 2rem;
    font-weight: 500;
    padding: 0;
    border: none;
  }
}
</style>
