<template>
  <nav class="sub-navigation">
    <Container fluid style="padding: 0">
      <button class="nav-button button--nostyle" @click="toggleNav">
        <span>{{ $t("common.menu") }}</span>
      </button>
      <ul
        class="list--nostyle nav-links"
        :class="isOpen ? 'open' : ''"
        ref="nav"
      >
        <li
          class="nav-item"
          v-for="item in navLinks"
          :key="item.translation_tag"
        >
          <router-link
            @click.native="closeNav"
            :to="item.url === 'summary' ? '/' : `/${$t(`url.${item.url}`)}`"
          >
            {{ $t(`dashboard.nav.${item.translation_tag}`) }}
          </router-link>
        </li>
      </ul>
    </Container>
  </nav>
</template>
<script>
import Container from "./Container.vue";
export default {
  components: { Container },
  name: "sub-navigation",
  data() {
    return {
      isOpen: false,
      navLinks: [
        {
          translation_tag: "summary",
          url: "summary",
        },
        {
          translation_tag: "background_info",
          url: "background-info",
        },
        {
          translation_tag: "commuting",
          url: "commuting",
        },
        {
          translation_tag: "safety",
          url: "safety",
        },
        {
          translation_tag: "business_trips",
          url: "meeting-trips",
        },
        {
          translation_tag: "exercise",
          url: "exercise",
        },
        {
          translation_tag: "change_potential",
          url: "change-potential",
        },
        {
          translation_tag: "feedback",
          url: "feedbacks",
        },
      ],
    };
  },
  methods: {
    toggleNav() {
      this.isOpen = !this.isOpen;
    },
    closeNav() {
      this.isOpen = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/style.scss";

.nav-links {
  column-gap: 2em;
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
  }

  &.open {
    display: flex;
    flex-direction: column;
    background-color: #ddd;
    margin-bottom: 2rem;
  }

  .nav-item {
    display: flex;

    a {
      color: #333;
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 3px solid transparent;
      width: 100%;
      text-align: center;
      transition: border-bottom 0.15s linear;
      @include media-breakpoint-up(md) {
        width: max-content;
      }

      &:hover {
        text-decoration: none;
        border-bottom: 3px solid $color-hsl-sininen;
      }

      &.router-link-exact-active {
        font-weight: 500;
        border-bottom: 3px solid $color-hsl-sininen;
        background-color: rgba(0, 0, 0, 0.1);

        @include media-breakpoint-up(md) {
          background-color: transparent;
        }
      }
    }
  }
}

.nav-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 1rem 0;
  column-gap: 1em;
  background-color: $color-hsl-sininen;
  color: #fff;
  font-size: 1rem;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
</style>
