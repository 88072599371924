<template>
  <div v-if="inquiries && inquiries.length > 0">
    <b-dropdown
      :text="activeItem.text"
      toggle-class="select-inquiry--button"
      variant="link"
      block
      right
    >
      <b-dropdown-item
        v-for="item in inquiriesDropdown"
        :key="item.value"
        @click="selectInquiry(item.value)"
      >
        {{ item.text }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      selectedInquiryId: (state) => state.inquiries.selectedInquiryId,
      inquiries: (state) => state.inquiries.inquiries,
    }),
    ...mapGetters("inquiries", ["inquiriesDropdown"]),
    activeItem() {
      if (this.selectedInquiryId) {
        return this.inquiriesDropdown.find(
          (item) => item.value === this.selectedInquiryId && item.text
        );
      }
      return this.inquiriesDropdown[0];
    },
  },
  methods: {
    selectInquiry(id) {
      this.$store.dispatch("inquiries/selectInquiry", id);
    },
  },
  mounted() {
    if (!this.selectedInquiryId && this.inquiries.length > 0) {
      this.selectInquiry(this.inquiries[0].queryId);
    }
  },
};
</script>
<style lang="scss">
@import "@/styles/style.scss";
.select-inquiry {
  &--button,
  &--button:hover,
  &--button:focus,
  &--button:active {
    color: #333 !important;
    background-color: transparent;
    text-align: left;
    border: none;
    font-weight: 500;
    font-size: 1.5rem;
    padding: 0;
    text-decoration: none;
    width: max-content;

    &::after {
      margin-left: 0.85rem;
      border-top-color: $color-hsl-sininen;
      border-radius: 5px;
    }
  }
}
</style>
